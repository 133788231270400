@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.halfcircle {
  border-radius: 0px 0px 200px 200px;
  border: 6px solid #404eed;
  border-top-color: transparent;
}
.shadow
{
  background: #FFFFFF;
/* border: 1px solid #404EED; */
box-shadow: inset 0px 4px 158px rgba(64, 78, 237, 0.19);
}

/* input[type="submit"]{ */
      /* margin-top: 1rem; */
      /* padding: 1rem; */
      /* background-color: #646cff; */
      /* color: white; */
      /* border: none; */
      /* border-radius: 5px; */
      /* cursor: pointer; */
	  /* white-space: normal; */
      /* width: 180px; */
    /* } */

.button_how {
	  margin-top: 1rem;
	  padding: 1rem;
	  background-color: #646cff;
	  color: white;
	  border: none;
	  border-radius: 5px;
	  cursor: pointer;
	  white-space: normal;
	  width: 180px;
}	